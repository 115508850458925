// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––
// NOTE
// html is set to 62.5% so that all the REM measurements throughout Skeleton
// are based on 10px sizing. So basically 1.5rem = 15px :)

html {
  font-size: 62.5%;
}

body {
  font-size: 1.5em; // currently ems cause chrome bug misinterpreting rems on body element
  line-height: 1.6;
  font-weight: 400;
  font-family: $font-family;
  color: $font-color;
  background: $primary-color;
}

// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $link-color;
  text-decoration: none;
}

// Misc
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid $light-grey;
}

.pr{
  position: relative;
}
.c_m{
  display: none;
}


.arrow-down {
width: 40px;
position: absolute;
bottom: 60px;
}

img.bw:hover {
  -webkit-filter: grayscale(0%);
  -webkit-transition: .5s ease-in-out;
  -moz-filter: grayscale(0%);
  -moz-transition: .5s ease-in-out;
  -o-filter: grayscale(0%);
  -o-transition: .5s ease-in-out;
} 

img.bw{
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%); 
  -moz-transition: .5s ease-in-out;
  -o-filter: grayscale(100%); 
  -o-transition: .5s ease-in-out;
}
// Header 
//––––––––––––––––––––––––––––––––––––––––––––––––––

