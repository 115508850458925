.header{
  padding-top: 25px;
  padding-bottom:25px;
  margin-left: -1em;
  padding-left: 1em;
  position: fixed;
  width: 80%;
  z-index: 999;
  background-color: rgba(0,0,0,0);
  font-family: SuisseReg;
  &.home {
    padding-top: 45px;
    a {
      font-size: 2rem;
    }
  }
  a{
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  &.smaller {
    padding-top: 25px;
  a {
    font-size: 1.5rem;
  }
}
  .menu{
    text-align: center;
    a{
      margin:0 10px;
    }

  }
  .contact{
    text-align: right;
    a{
      margin-left: 20px;
    }
  }
}
.header .menu a:hover, .contact a:hover{
  text-decoration: underline;
}
.header .menu a.active {
  text-decoration: underline;
}

@media only screen and (max-width: 1200px) {
.header {
  
    a {
      font-size: 1.5rem;
    }
  }

}

@media only screen and (max-width: 700px) {

  .header{
    text-align: left !important;
    padding-top: 15px;
    line-height: 2rem;
    padding-bottom: 0;
    &.home {
      padding-top: 15px;
    }
    .logo{
      width: 100%;
      padding-bottom: 15px;
      text-align: left;
      float: left;
      font-size: 1rem; 
      a{font-size: 1.5rem !important;}
    }.menu{
      text-align: right;
      width: 100%;
      float: right;
      margin-top: -37px;
      margin-bottom: 10px;
      a {
        font-size: 1.5rem !important;
      }
      a#about {
        margin-right: 0;
      }
    }.contact{
      display: none;
    }.c_m{
      display: inline;
    }
  }
}

