.project {
	margin-top: 15vh;
	img {
		width: 100%;
	}
}
.projectmeta {

	margin-top: 15vh;
	border-top: 2px solid white;
	padding-top: 1rem;
	font-size: 1.3rem;
	position: fixed;
	right: 10%;
	.meta {
		font-size: 1rem;
	}

}


.embed-container {
	margin-bottom: 2vh;
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.projectdesc {
	margin-bottom: 5vh;
	font-size: 1.3rem;
	line-height: 2rem;
	text-align: justify;
	img {
		max-width: 100%;
		margin-bottom: 2vh;

	}
}

@media only screen and (max-width: 700px) {
	.project {
		margin-top: 10vh;
	}
	.projectmeta {
		margin-top: 0;
		position: relative;
		right: 0;
	}

}

@media only screen and (max-width: 1000px) {
	.projectdesc {
		text-align: left;
	}
}
