.intro {
	line-height: 85px;
	text-align: left;
	display: table;
	height: 40vh;
}
.intro_right {
	height: 100vh;
	line-height: 40px;


}
.text {
	display: table-cell;
	vertical-align: middle;
}

.tiny {
	line-height: 20px;
	font-size: 1.4rem;
	text-align: right;
}


@media only screen and (max-width: 700px) {

	.intro{
	//	text-align:center;
	}

}
