.work {
  //height: 30vh;
  display: table;
  padding-top: 3em;
  //padding-bottom: 5px;
  margin-bottom: 2em;
  border-top: 2px solid white;
}
.title {
//  margin-left: -2em;
   -ms-transform: rotate(270deg); /* IE 9 */
    -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
    transform: rotate(270deg);
    text-align: left;
    position: relative;
    float: left;
    margin-left: -3em;
    //padding-top: 3em;
    font-size: 1.5rem;
   // line-height: 1rem;
}
.category {
    font-size: 1rem;
    line-height: 0rem;
      text-align: right;
}
.work a{
  display: block;
  float: left;
  position: relative;
  list-style:none;
  width: 32.3%;
  margin-bottom: 1.5%;
  margin-left: 1.5%;
  line-height: 0;
}
.work a img{
  list-style:none;
  border:0;
  margin:0;
  padding:0;
  width: 100%;
}
.work p{
  display: none;
}
.work a:hover p{
  display: table;
  background-color:rgba(0,0,0,1);
  color: white;
  position: absolute;
  width: 100%;
  min-height: 100%;
  padding: 10px;
  top: 0;
  box-sizing: border-box;
  border: white 2px solid;
}
.work p span{
  font-family: SuisseReg;
  display: table-cell;
  vertical-align: top;
  font-size: 3rem;
  line-height: 1.5em;
  strong {
    font-size: 1rem;
    line-height:1.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    font-family: SuisseReg;
    font-weight: 100;
    float: right;
    vertical-align: middle;
  }
}
.work a {
}

.work a:nth-child(3n+1) {
  margin-left: 0;
}


@media only screen and (max-width: 1200px) {
  .work a {
    width: 49% !important;
  }

  .work a:nth-child(3n+4) {
    margin-left: 1.5%;
  }
  .work a:nth-child(2n+1) {
    margin-left: 0;
  }

}
@media only screen and (max-width: 1000px) {
  .work p span {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 700px) {
.title {
      margin-left: -1em;
      //display:none;
}
.category {
  //display: none;
}

  .work a {
    margin-left: 0;
    margin-bottom: 1.5%;
    width: 100% !important;
  }
  .work a {
    margin-left: 0;
  }
  .work p span {
    font-size: 3rem;
  }
  .work a:nth-child(3n+4) {
    margin-left: 0;
  }
  .work {
    //  border-top: none;

  }
  
}

