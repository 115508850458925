.about {
	padding-top: 18vh;
		display: table;

	img {
		max-width: 100%;
		border: 2px white solid;
	}
	p {
		vertical-align: center;
		font-size: 2rem;
		//line-height: 3rem;
		&.listtitle {
			float: left;
			font-size: 1.5rem;
		}
	}
	ul {
		display: inline-block;
		margin-left: 2em;
		list-style: none;
	li {
		margin-bottom: 0;
		//display: inline-block;
		margin-left: 2em;
	}
	}

}
@media only screen and (max-width: 700px) {
	.about {
		padding-top: 10vh;
		img {
			margin-bottom: 1em;
		}
		p {
			font-size: 2rem;
		}
	}
	
}